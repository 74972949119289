import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { Router } from "@angular/router";
import { FuseConfigService } from "@fuse/services/config.service";
import { navigation } from "app/navigation/navigation";
import { ProjectService } from "app/services/project/project.service";

@Component({
	selector: "vertical-layout-1",
	templateUrl: "./layout-1.component.html",
	styleUrls: ["./layout-1.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
	fuseConfig: any;
	navigation: any;

	renderBadgeBar: boolean = false;

	// Private
	private _unsubscribeAll: Subject<any>;

	/**
	 * Constructor
	 *
	 * @param {FuseConfigService} _fuseConfigService
	 */
	constructor(
		private _fuseConfigService: FuseConfigService,
		private _projectSvc: ProjectService,
		private _router: Router,
	) {
		// Set the defaults
		this.navigation = navigation;

		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Subscribe to config changes
		this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			this.fuseConfig = config;
		});

		if (!this._router.url.includes("/login")) {
			this._fuseConfigService.setConfig({
				layout: {
					navbar: { hidden: true },
					toolbar: { hidden: true },
					footer: { hidden: true },
					sidepanel: { hidden: true },
				},
			});
		}

		if (localStorage.getItem("tokenUser")) {
			//Mostar la barra solo en Leroy
			this._projectSvc.getProjectConfig(false).subscribe((project) => {
				if (project.entryPoint.showTimeline) {
					this.renderBadgeBar = false;
				}
			});
		}
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
}
