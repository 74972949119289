import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { AuthService } from "../auth/auth.service";
import { AlertError } from "../error-handler";
import { HelpersService } from "../helper/helpers.service";
import { ProjectService } from "../project/project.service";

@Injectable({
	providedIn: "root",
})
export class PersonService {
	searchClose: Subject<any>;

	constructor(
		private http: HttpClient,
		private authSvc: AuthService,
		private projectSvc: ProjectService,
		private helpersSvc: HelpersService,
	) {
		this.searchClose = new Subject();
	}

	getIdPerson(username: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/username/" + username;
			this.http.get(url, options).subscribe(
				(response: any) => {
					if (response) {
						observer.next(response.id);
					} else {
						observer.error("Se ha producido un error");
					}
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getPersons(name: string, numPage: number, pageSize?: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				displayName: name,
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
			};

			const url = environment.urlDomain + "/api/v1/persons?" + this.helpersSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getPersonsWithMe(name: string, numPage: number, pageSize?: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				displayName: name,
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				showMe: 1,
			};

			const url = environment.urlDomain + "/api/v1/persons?" + this.helpersSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getPerson(personId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/" + personId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getProfile(personId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/" + personId + "/profile";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	puestoInfo(personId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/" + personId + "/puestoInfo";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getCompany(personId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/" + personId + "/company";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getJobSections(personId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/" + personId + "/jobSections";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	addJobSections(personId: number, sectionId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/" + personId + "/jobSections/" + sectionId;
			this.http.post(url, null, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	removeJobSections(personId: number, sectionId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/" + personId + "/jobSections/" + sectionId;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getKpiDataByEvaluationElement(personId: number, evalKpiId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/" + personId + "/kpiData/" + evalKpiId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getAllMails(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/mails/allusermails";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getListaTrabajadores(bossId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/listaTrabajadores/" + bossId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	putDelegacion(id_persona: number, id_dele: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/changeDelegation/" + id_persona + "/" + id_dele;
			this.http.put(url, {}, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	putAficiones(id_persona: number, aficiones: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/changeAficiones/" + id_persona;
			this.http.put(url, { aficiones: aficiones }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getTimeTemplate(id: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/" + id + "/template";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getDelegacion(person_id: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			const url = environment.urlDomain + "/api/v1/persons/delegacion/" + person_id;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error getJobSections", err);
					let msgError = "No se han podido cargar los datos";

					observer.error(msgError);
				},
			);
		});
	}

	peopleFromDepartament(departamentoId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			const url = environment.urlDomain + "/api/v1/persons/peopleFromDepartament/" + departamentoId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error getJobSections", err);
					let msgError = "No se han podido cargar los datos";

					observer.error(msgError);
				},
			);
		});
	}

	peopleFromPuesto(puestoId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			const url = environment.urlDomain + "/api/v1/persons/peopleFromPuesto/" + puestoId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error peopleFromPuesto", err);
					let msgError = "No se han podido cargar los datos";

					observer.error(msgError);
				},
			);
		});
	}

	peopleFromPuestoName(name: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			const url = environment.urlDomain + "/api/v1/persons/peopleFromPuestoName?" + this.helpersSvc.serializeData({ name });
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error peopleFromPuestoName", err);
					let msgError = "No se han podido cargar los datos";

					observer.error(msgError);
				},
			);
		});
	}

	peopleFromDelegacion(delegacionId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			const url = environment.urlDomain + "/api/v1/persons/peopleFromDelegacion/" + delegacionId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error getJobSections", err);
					let msgError = "No se han podido cargar los datos";

					observer.error(msgError);
				},
			);
		});
	}

	peopleFromPuestoAgrupado(puestoAgrupadoId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			const url = environment.urlDomain + "/api/v1/persons/peopleFromAgrupado/" + puestoAgrupadoId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error getJobSections", err);
					let msgError = "No se han podido cargar los datos";

					observer.error(msgError);
				},
			);
		});
	}

	getDocumentos(personId) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/documentos/" + personId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getDocumentosTAS(dni: string): Observable<any> {
		const accessToken = this.authSvc.getAccessToken();
		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
				"Content-Type": "application/json; charset=utf-8",
			}),
		};

		const url = environment.urlDomain + "/api/v1/persons/documentosTAS/" + dni;
		return this.http.get(url, options);
	}

	getArchivoDocumentoTAS(docId: number): Observable<any> {
		const accessToken = this.authSvc.getAccessToken();
		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
				"Content-Type": "application/json; charset=utf-8",
			}),
			responseType: "blob" as "json", // Convertir a 'json' para la asignación de tipos
		};

		const url = `${environment.urlDomain}/api/v1/persons/documentosTAS/archivo/${docId}`;

		return this.http.get(url, options).pipe(map((response: any) => new Blob([response], { type: "application/octet-stream" })));
	}

	guardarDocumento(formulario, personId, file: File) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
				}),
			};

			const formData = new FormData();
			formData.append("file", file, file.name);
			formData.append("title", formulario.title);
			formData.append("personId", personId);

			const url = environment.urlDomain + "/api/v1/persons/documento/" + personId;
			this.http.post(url, formData, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getDocumento(documentId: number): Observable<any> {
		const accessToken = this.authSvc.getAccessToken();
		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
				"Content-Type": "application/json; charset=utf-8",
			}),
			responseType: "blob" as "json", // Convertir a 'json' para la asignación de tipos
		};

		const url = `${environment.urlDomain}/api/v1/persons/document/${documentId}`;

		return this.http.get(url, options).pipe(map((response: any) => new Blob([response], { type: "application/octet-stream" })));
	}

	changeEstadoDocument(documentId: number, estado: number) {
		const accessToken = this.authSvc.getAccessToken();
		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
				"Content-Type": "application/json; charset=utf-8",
			}),
		};

		return new Observable<any>((observer) => {
			const url = environment.urlDomain + "/api/v1/persons/changeEnabledDocument/" + documentId;
			this.http.put(url, { enabled: estado }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	deleteDocumento(documentId: number) {
		const accessToken = this.authSvc.getAccessToken();
		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
			}),
		};

		return new Observable<any>((observer) => {
			const url = environment.urlDomain + "/api/v1/persons/document/" + documentId;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	addPuesto(personId, datos) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/puesto/" + personId;
			this.http.post(url, datos, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	quitarPuesto(puestoId) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/puesto/" + puestoId;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	addDelegacion(personId, datos) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/delegacion/" + personId;
			this.http.post(url, datos, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	quitarDelegacion(delegacionId) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/delegacion/" + delegacionId;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	addGrupo(personId, datos) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/group-comp/" + personId;
			this.http.post(url, datos, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	quitarGrupo(personId, grupoId) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/group-comp/" + personId + "/" + grupoId;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	setEstado(personId, estado: boolean) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/estado/" + personId;
			this.http.put(url, { estado: estado }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	setBloqueo(personId, bloqueo: boolean) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/bloqueo/" + personId;
			this.http.put(url, { bloqueo: bloqueo }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getVideosOnboarding(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/user/onboarding/videos";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getVideosVistosOnboarding(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/user/onboarding/vistos";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	setVideoVistosOnboarding(id_video: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/user/onboarding/videoVisto";
			this.http.post(url, { id_video }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getMailMFA(person): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),

				params: new HttpParams({
					fromObject: {
						personId: person,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/mailMFA/sendMail";
			this.http.put(url, {}, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	personOnboardingDone(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/user/onboarding/done";
			this.http.post(url, {}, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	putMFA(type): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/personMFA/change";
			this.http.put(url, { type }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	peopleDirectorio(filtros: any, numPage: number, pageSize: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				page: numPage,
				limit: pageSize ?? environment.pageSize,
			};

			const parametros = { ...params, ...filtros };

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/persons/directorio/peopleDirectorio?${this.helpersSvc.serializeData(parametros)}`;

			this.http.get(url, options).subscribe(
				(response: any) => observer.next(response),
				(err) => AlertError.showError(err),
			);
		});
	}
}
