import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";

import { Router } from "@angular/router";
import { ProjectService } from "app/services/project/project.service";
import { PersonService } from "../../../app/services/person/person.service";

@Component({
	selector: "fuse-search-bar",
	templateUrl: "./search-bar.component.html",
	styleUrls: ["./search-bar.component.scss"],
})
export class FuseSearchBarComponent implements OnInit, OnDestroy {
	collapsed: boolean;
	fuseConfig: any;
	projectId;

	@Output()
	input: EventEmitter<any>;
	@Output()
	close: EventEmitter<any>;

	@ViewChild("searchInput") searchInput;

	// Private
	private _unsubscribeAll: Subject<any>;

	/**
	 * Constructor
	 *
	 * @param {FuseConfigService} _fuseConfigService
	 */
	constructor(
		private _fuseConfigService: FuseConfigService,
		private personSvc: PersonService,
		private projectSvc: ProjectService,
		private router: Router,
	) {
		// Set the defaults
		this.input = new EventEmitter();
		this.close = new EventEmitter();
		this.collapsed = true;

		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.projectId = parseInt(this.projectSvc.getProjectId());
		// Subscribe to config changes
		this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			this.fuseConfig = config;
		});

		this.personSvc.searchClose.subscribe(() => {
			this.collapse();
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Collapse
	 */
	collapse(): void {
		this.collapsed = true;
		this.searchInput.nativeElement.value = "";
		this.close.emit();
	}

	/**
	 * Expand
	 */
	expand(): void {
		this.collapsed = false;
	}

	/**
	 * Search
	 *
	 * @param event
	 */
	search(event): void {
		this.input.emit(event.target.value);
	}

	goToDirectorio() {
		this.router.navigate(["/directorio"]);
	}
}
