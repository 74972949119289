import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { Injector, LOCALE_ID, NgModule } from "@angular/core";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";

import { environment } from "../environments/environment";

import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from "@fuse/components";
import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";

import { fuseConfig, fuseConfigAdministracion } from "app/fuse-config";

import { AuthGuardService } from "app/services/auth/auth-guard.service";
import { AuthService } from "app/services/auth/auth.service";
import { MustChangePasswordGuardService } from "app/services/auth/must-change-password-guard.service";
import { ValidateHashChangePasswordGuardService } from "app/services/auth/validate-hash-chage-password-guard.service";
import { BadgesService } from "app/services/badges/badges.service";
import { ExcelService } from "app/services/excel/excel.service";
import { HelpersService } from "app/services/helper/helpers.service";
import { ImagesService } from "app/services/helper/images.service";
import { ProjectService } from "app/services/project/project.service";
import { UserService } from "app/services/user/user.service";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { CodigoSeguridadModule } from "app/main/codigo-seguridad/codigo-seguridad.module";
import { InicioModule } from "app/main/inicio/inicio.module";
import { LoginModule } from "app/main/login/login.module";

import { DatePipe, registerLocaleData } from "@angular/common";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { CompleteDataModule } from "app/main/complete-data/complete-data.module";
import { DialogDarBadgeComponent } from "./components/dialog-dar-badge/dialog-dar-badge.component";
import { LoadingInterceptor } from "./interceptor/loading.interceptor";
import { LegalTermsModule } from "./main/legal-terms/legal-terms.module";
import { PollModule } from "./main/poll/poll.module";
import { SiedModule } from "./main/sied/sied.module";
import { VueltaDModule } from "./main/vuelta-d/vuelta-d.module";
import { CustomTranslateLoader } from "./services/custom-translate-loader";

import { MatDatepickerModule } from "@angular/material/datepicker";
import { SharedComponentsModule } from "./components/shared-components.module";
import { CheckRolGuard } from "./services/graficas/checkrol-guard.service";
import { isBossGuard } from "./services/liquidaciones/isBoss-guard.service";
import { LiquidacionesActivasGuard } from "./services/liquidaciones/liquidacionesActivas-guard.service";
import { OrganigramaActivoGuard } from "./services/organigrama/OrganigramaActivo-guard.service";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { AccionesFormativasGuard } from "./services/acciones-formativas/acciones-formativas-guard.service";
import { CheckRolBienestarGuard } from "./services/acciones-formativas/checkrol-guard.service";
import { BadgeModerationGuard } from "./services/badges/badge-moderation-guard.service";

import "hammerjs";

let activeFuseConfig = fuseConfig;
if (window.location.href.includes("/administracion/")) {
	activeFuseConfig = fuseConfigAdministracion;
}

import localeEs from "@angular/common/locales/es";
import { provideCharts, withDefaultRegisterables } from "ng2-charts";
import { CheckOnlyManu } from "./services/admin/checkonly-manu.service";
registerLocaleData(localeEs, "es-ES");

const appRoutes: Routes = [
	{
		path: "chat",
		loadChildren: () => import("./main/chat/chat.module").then((m) => m.ChatModule),
		canActivate: [AuthGuardService, MustChangePasswordGuardService],
	},
	{
		path: "perfil/:username",
		loadChildren: () => import("./main/perfil/perfil.module").then((m) => m.PerfilModule),
		canActivate: [AuthGuardService, MustChangePasswordGuardService],
	},
	{
		path: "perfil",
		loadChildren: () => import("./main/perfil/perfil.module").then((m) => m.PerfilModule),
		canActivate: [AuthGuardService, MustChangePasswordGuardService],
	},
	{
		path: "sied",
		loadChildren: () => import("./main/sied/sied.module").then((m) => m.SiedModule),
		canActivate: [AuthGuardService, MustChangePasswordGuardService],
	},
	{
		path: "evaluacion",
		loadChildren: () => import("./main/evaluacion/evaluacion.module").then((m) => m.EvaluacionModule),
		canActivate: [AuthGuardService, MustChangePasswordGuardService],
	},
	{
		path: "reserva-salas",
		loadChildren: () => import("./main/reserva-salas/reserva-salas.module").then((m) => m.ReservaSalasModule),
		canActivate: [AuthGuardService, MustChangePasswordGuardService],
	},
	{
		path: "expenses",
		loadChildren: () => import("./main/expenses/expenses.module").then((m) => m.ExpensesModule),
		canActivate: [AuthGuardService, MustChangePasswordGuardService],
	},
	{
		path: "publicaciones",
		loadChildren: () => import("./main/publicaciones/publicaciones.module").then((m) => m.PublicacionesModule),
		canActivate: [AuthGuardService, MustChangePasswordGuardService],
	},
	{
		path: "onboarding",
		loadChildren: () => import("./main/onboarding/onboarding.module").then((m) => m.OnboardingModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "shop",
		loadChildren: () => import("./main/shop/shop.module").then((m) => m.ShopModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "administracion/signaturit",
		loadChildren: () => import("./main/signaturit/signaturit.module").then((m) => m.SignaturitModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "help",
		loadChildren: () => import("./main/help/help.module").then((m) => m.HelpModule),
		canActivate: [AuthGuardService, MustChangePasswordGuardService],
	},
	{
		path: "directorio",
		loadChildren: () => import("./main/directorio/directorio.module").then((m) => m.DirectorioModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "buen-gobierno",
		loadChildren: () => import("./main/help-buen-gobierno/help-buen-gobierno.module").then((m) => m.HelpBuenGobiernoModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "email-signature",
		loadChildren: () => import("./main/email-signature/email-signature.module").then((m) => m.EmailSignatureModule),
		canActivate: [AuthGuardService, MustChangePasswordGuardService],
	},
	{
		path: "rememberPassword/:idUser/:date/:hash",
		loadChildren: () => import("./main/remember-password/remember-password.module").then((m) => m.RememberPasswordModule),
		canActivate: [ValidateHashChangePasswordGuardService],
	},
	{
		path: "control-horario",
		loadChildren: () => import("./main/control-horario/control-horario.module").then((m) => m.ControlHorarioModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "formaciones",
		loadChildren: () => import("./main/formaciones/formaciones.module").then((m) => m.FormacionesModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "ranking/:year/:month",
		loadChildren: () => import("./main/rankings/rankings.module").then((m) => m.RankingsModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "rankings",
		loadChildren: () => import("./main/rankings/rankings.module").then((m) => m.RankingsModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "account",
		loadChildren: () => import("./main/account/account.module").then((m) => m.AccountModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "login",
		loadChildren: () => import("./main/login/login.module").then((m) => m.LoginModule),
	},
	{
		path: "login-cas",
		loadChildren: () => import("./main/login/login.module").then((m) => m.LoginModule),
	},
	{
		path: "complete-data",
		loadChildren: () => import("./main/complete-data/complete-data.module").then((m) => m.CompleteDataModule),
	},
	{
		path: "legal-terms",
		loadChildren: () => import("./main/legal-terms/legal-terms.module").then((m) => m.LegalTermsModule),
	},
	{
		path: "vuelta-d",
		loadChildren: () => import("./main/vuelta-d/vuelta-d.module").then((m) => m.VueltaDModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "poll",
		loadChildren: () => import("./main/poll/poll.module").then((m) => m.PollModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "portal-empleo",
		loadChildren: () => import("./main/portal-empleo/portal-empleo.module").then((m) => m.PortalEmpleoModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "graficas",
		loadChildren: () => import("./main/graficas/graficas.module").then((m) => m.GraficasModule),
		canActivate: [AuthGuardService, CheckRolGuard],
	},
	{
		path: "liquidaciones/ficha",
		loadChildren: () => import("./main/liquidaciones/liquidaciones.module").then((m) => m.LiquidacionesModule),
		canActivate: [AuthGuardService, LiquidacionesActivasGuard],
	},
	{
		path: "liquidaciones/salary",
		loadChildren: () => import("./main/liquidaciones/retribuciones/retribuciones.module").then((m) => m.RetribucionesModule),
		canActivate: [AuthGuardService, LiquidacionesActivasGuard],
	},
	{
		path: "liquidaciones/calculator",
		loadChildren: () => import("./main/liquidaciones/calculadora/calculadora.module").then((m) => m.CalculadoraModule),
		canActivate: [AuthGuardService, LiquidacionesActivasGuard],
	},
	{
		path: "liquidaciones/fichaTrabajador/:id",
		loadChildren: () => import("./main/liquidaciones/ficha/ficha.module").then((m) => m.FichaModule),
		canActivate: [AuthGuardService, LiquidacionesActivasGuard, isBossGuard],
	},
	{
		path: "liquidaciones/retribucion/:id",
		loadChildren: () => import("./main/liquidaciones/retribucionConcreta/retribucionConcreta.module").then((m) => m.RetribucionConcretaModule),
		canActivate: [AuthGuardService, LiquidacionesActivasGuard],
	},
	//Vacaciones & Incidencias
	{
		path: "vacaciones/user",
		loadChildren: () => import("./main/vacaciones-incidencias/vacaciones/vacaciones.module").then((m) => m.VacacionesModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "vacaciones/team",
		loadChildren: () => import("./main/vacaciones-incidencias/vacaciones-equipo/vacaciones-equipo.module").then((m) => m.VacacionesEquipoModule),
		canActivate: [AuthGuardService],
	},
	/*
    {
        path        : 'incidencias/user',
        loadChildren: () => import('./main/vacaciones-incidencias/incidencias/incidencias.module').then(m => m.IncidenciasModule),
        canActivate: [AuthGuardService],
    },
    */
	{
		path: "vacaciones/new",
		loadChildren: () => import("./main/vacaciones-incidencias/vacaciones/new/new-vacaciones.module").then((m) => m.NewVacacionesModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "vacaciones/new-pro",
		loadChildren: () => import("./main/vacaciones-incidencias/vacaciones/new-pro/new-pro-vacaciones.module").then((m) => m.NewProVacacionesModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "incidencias/new",
		loadChildren: () => import("./main/vacaciones-incidencias/incidencias/new/new-incidencia.module").then((m) => m.NewIncidenciaComponentModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "vacaciones/admin/:idSol",
		loadChildren: () =>
			import("./main/vacaciones-incidencias/vacaciones-equipo/aprobar-rechazar/aprobar-rechazar.module").then((m) => m.AprobarRechazarModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "organigrama",
		loadChildren: () => import("./main/organigrama/organigrama.module").then((m) => m.OrganigramaModule),
		canActivate: [AuthGuardService, OrganigramaActivoGuard],
	},
	//Dashboard
	/*
    {
        path        : 'dashboard/admin',
        loadChildren: () => import('./main/dashboard/admin/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuardService,RoleCheckerGuard],
    },
    {
        path        : 'dashboard/delegacion',
        loadChildren: () => import('./main/dashboard/delegacion-dashboard/delegacion-dashboard.module').then(m => m.DelegacionDashModule),
        canActivate: [AuthGuardService,RoleCheckerDelegacionGuard],
    },
    {
        path        : 'dashboard/admin-boss',
        loadChildren: () => import('./main/dashboard/admin-boss-dashboard/admin-boss-dashboard.module').then(m => m.AdminBossDashboardModule),
        canActivate: [AuthGuardService,BossCheckerGuard],
    },
    */
	{
		path: "formaciones-bonificaciones",
		loadChildren: () => import("./main/acciones-formativas/bonificaciones/bonificaciones.module").then((m) => m.BonificacionesModule),
		canActivate: [AuthGuardService, AccionesFormativasGuard, CheckRolBienestarGuard],
	},
	{
		path: "formaciones-solicitudes",
		loadChildren: () => import("./main/acciones-formativas/solicitudes-usuarios/solicitudes-formacion.module").then((m) => m.SolicitudesModule),
		canActivate: [AuthGuardService, AccionesFormativasGuard, CheckRolBienestarGuard],
	},
	{
		path: "formaciones-acciones",
		loadChildren: () => import("./main/acciones-formativas/acciones_formativas/acciones-formativas.module").then((m) => m.AccionesFormativasModule),
		canActivate: [AuthGuardService, AccionesFormativasGuard, CheckRolBienestarGuard],
	},
	{
		path: "formaciones-externas",
		loadChildren: () =>
			import("./main/acciones-formativas/formaciones-externas/formaciones-externas.module").then((m) => m.FormacionesExternasModule),
		canActivate: [AuthGuardService, AccionesFormativasGuard, CheckRolBienestarGuard],
	},
	{
		path: "formaciones-grupos",
		loadChildren: () => import("./main/acciones-formativas/grupos-formativos/grupos-formativos.module").then((m) => m.GruposFormativosModule),
		canActivate: [AuthGuardService, AccionesFormativasGuard, CheckRolBienestarGuard],
	},
	{
		path: "formaciones-asociaciones",
		loadChildren: () => import("./main/acciones-formativas/personas-grupos/personas-grupos.module").then((m) => m.GruposPersonasModule),
		canActivate: [AuthGuardService, AccionesFormativasGuard, CheckRolBienestarGuard],
	},
	{
		path: "formaciones-temas",
		loadChildren: () => import("./main/acciones-formativas/temas-formacion/temas-formacion.module").then((m) => m.TemasModule),
		canActivate: [AuthGuardService, AccionesFormativasGuard, CheckRolBienestarGuard],
	},
	{
		path: "formaciones-menu",
		loadChildren: () => import("./main/menu-formaciones/menu-formaciones.module").then((m) => m.MenuFormacionesModule),
		canActivate: [AuthGuardService, AccionesFormativasGuard],
	},
	{
		path: "formaciones-informes",
		loadChildren: () => import("./main/acciones-formativas/informes/informes-formaciones.module").then((m) => m.InformesModule),
		canActivate: [AuthGuardService, AccionesFormativasGuard, CheckRolBienestarGuard],
	},
	{
		path: "formaciones-proveedores",
		loadChildren: () => import("./main/acciones-formativas/proveedores/proveedores.module").then((m) => m.ProveedoresModule),
		canActivate: [AuthGuardService, AccionesFormativasGuard, CheckRolBienestarGuard],
	},
	{
		path: "administracion/informes",
		loadChildren: () => import("./admin/informes/informes.module").then((m) => m.InformesModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/usuarios",
		loadChildren: () => import("./admin/usuarios/usuarios.module").then((m) => m.UsuariosModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/evaluaciones",
		loadChildren: () => import("./admin/evaluaciones/evaluaciones.module").then((m) => m.EvaluacionesModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
		/*children:[
            {
              path: 'cesiones',
              component: CesionesComponent
            }]*/
	},
	/*{
        path:'administracion/evaluaciones/cesiones',
        component:  CesionesComponent
       /*loadChildren: () => import('./admin/evaluaciones/evaluaciones.module').then(m => m.EvaluacionesModule),
        canActivate: [AuthGuardService],
        data: {
			environment: ['ADMIN']
		}*/

	//},
	{
		path: "administracion/medallas",
		loadChildren: () => import("./admin/medallas/medallas.module").then((m) => m.MedallasModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/medallas",
		loadChildren: () => import("./admin/medallas/medallas.module").then((m) => m.MedallasModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/vacaciones",
		loadChildren: () => import("./admin/vacaciones/vacaciones.module").then((m) => m.VacacionesModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/organigrama",
		loadChildren: () => import("./admin/organigrama/organigrama.module").then((m) => m.OrganigramaModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/gastos",
		loadChildren: () => import("./admin/gastos/gastos.module").then((m) => m.AdminGastosModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/posts",
		loadChildren: () => import("./admin/publicaciones/publicaciones.module").then((m) => m.PublicacionesModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/control-horario",
		loadChildren: () => import("./admin/control-horario/control-horario.module").then((m) => m.ControlHorarioModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/control-horario",
		loadChildren: () => import("./admin/control-horario/control-horario.module").then((m) => m.ControlHorarioModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/shop",
		loadChildren: () => import("./admin/shop/shop.module").then((m) => m.ShopModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/centro-ayuda",
		loadChildren: () => import("./admin/centro-ayuda/centro-ayuda.module").then((m) => m.CentroAyudaModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/proyectos",
		loadChildren: () => import("./admin/proyectos/proyectos.module").then((m) => m.ProyectosModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/permisos",
		loadChildren: () => import("./admin/permisos/permisos.module").then((m) => m.PermisosModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/politicas",
		loadChildren: () => import("./admin/politicas/politicas.module").then((m) => m.PoliticasModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "politicas",
		loadChildren: () => import("./main/politicas-web/politicas-web.module").then((m) => m.PoliticasWebModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/iHbmSlJjSpMnLl",
		loadChildren: () => import("./admin/new-video/new-video.module").then((m) => m.NewVideoModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/MlPmNlsJsRlBpS98",
		loadChildren: () => import("./admin/new-resource/new-resource.module").then((m) => m.NewResourceModule),
		canActivate: [AuthGuardService, CheckOnlyManu],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/liquidaciones",
		loadChildren: () => import("./admin/liquidaciones/liquidaciones.module").then((m) => m.LiquidacionesModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/portal-empleo",
		loadChildren: () => import("./admin/portal-empleo-admin/portal-empleo.module").then((m) => m.PortalEmpleoModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "administracion/tema",
		loadChildren: () => import("./admin/tema/tema-admin.module").then((m) => m.TemaAdminModule),
		canActivate: [AuthGuardService],
		data: {
			environment: ["ADMIN"],
		},
	},
	{
		path: "graficas-externas",
		loadChildren: () => import("./main/graficas-externas/graficas-externas.module").then((m) => m.GraficasExternasModule),
	},
	{
		path: "moderar-badge/:idBadge",
		loadChildren: () => import("./main/badge-moderation/badge-moderation.module").then((m) => m.BadgeModerationModule),
		canActivate: [AuthGuardService, BadgeModerationGuard],
	},
	{
		path: "administracion",
		redirectTo: "administracion/usuarios",
	},
	{
		path: "**",
		redirectTo: "inicio",
	},
];

@NgModule({
	declarations: [AppComponent, DialogDarBadgeComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		RouterModule.forRoot(appRoutes, { scrollPositionRestoration: "enabled", onSameUrlNavigation: "reload" }),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: CustomTranslateLoader,
				deps: [HttpClient, Injector],
			},
		}),
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireAuthModule,

		FontAwesomeModule,
		InfiniteScrollModule,
		SharedComponentsModule,

		// Material moment date module
		MatMomentDateModule,

		//IDLE module
		NgIdleKeepaliveModule.forRoot(),

		// Material
		MatButtonModule,
		MatIconModule,
		MatDialogModule,
		MatFormFieldModule,
		MatInputModule,
		MatSnackBarModule,
		MatTabsModule,
		MatChipsModule,
		MatCheckboxModule,
		MatAutocompleteModule,
		// Fuse modules
		FuseModule.forRoot(fuseConfig),
		FuseProgressBarModule,
		FuseSharedModule,
		FuseSidebarModule,
		FuseThemeOptionsModule,

		// App modules
		LayoutModule,
		InicioModule,
		LoginModule,
		CodigoSeguridadModule,
		CompleteDataModule,
		SiedModule,
		LegalTermsModule,
		VueltaDModule,
		PollModule,

		//Forms
		FormsModule,
		ReactiveFormsModule,
		MatDatepickerModule,
		MatNativeDateModule,
	],
	providers: [
		AuthService,
		ProjectService,
		UserService,
		BadgesService,
		ImagesService,
		HelpersService,
		ExcelService,
		DatePipe,
		CheckRolGuard,
		CheckRolBienestarGuard,
		CheckOnlyManu,
		provideCharts(withDefaultRegisterables()),
		{ provide: LOCALE_ID, useValue: "es-ES" },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoadingInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(library: FaIconLibrary) {
		library.addIconPacks(fas);
	}
}
